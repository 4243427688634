import { useState, useEffect } from 'react'

import { CSSTransition } from 'react-transition-group'

import axios from 'axios'

import Modal from '../components/elements/Modal'
import Confirm from '../components/modals/Confirm'
import SelectRole from '../components/modals/SelectRole'

import Category from '../components/sections/shop/Category'
import RushCategory from '../components/sections/shop/RushCategory'
import RushCategoryNoT from '../components/sections/shop/RushCategoryNoT'
import Product from '../components/sections/shop/Product'
import Success from '../components/modals/Success'
import { isDefined } from '../helpers/shop'
import SpecialPromo from '../components/sections/shop/SpecialPromo'

const Shop = ({ setLoggedIn }) => {
  const [promocode, setPromocode] = useState('')
  const [promoProducts, setPromoProducts] = useState({})

  const [products, setProducts] = useState([])

  const [daily, setDaily] = useState({})
  const [weekly, setWeekly] = useState({})
  const [exclusive, setExclusive] = useState({})
  const [rush, setRush] = useState({})
  const [season, setSeason] = useState({})
  const [seasonBoost, setBoost] = useState({})
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [party, setParyt] = useState({})
  const [partyBoost, setParytBoost] = useState({})
  const [city, setCity] = useState({})
  const [kits, setKits] = useState({})
  const [bigq, setBigq] = useState({})

  const [fuss, setFuss] = useState({})
  const [reg, setReg] = useState({})

  const [visible, setVisible] = useState(false)

  const team = JSON.parse(sessionStorage.getItem('team'))

  useEffect(() => {
    fetchShop()
  }, [])

  const fetchShop = async () => {
    try {
      if (!team) {
        throw new Error('Team not found')
      }

      const response = await Promise.all([
        axios.get(`/Market/GetActivatedUserPromocode/${team.id}`),
        axios.get(`/Market/GetPromocodeLotsForWeb/${team.id}`),
        axios.get('/Market/GetRegularLotsForWeb'),
        axios.get(`/Market/GetAvailibleDailyLotsForWeb/${team.id}`),
        axios.get(`/Market/GetAvailibleWeeklyLotsForWeb/${team.id}`),
        axios.get(`/Market/GetNewbieLotsForWeb/${team.id}`),
        axios.get(`/Market/GetRushLotsForWeb/${team.id}`),
        axios.get(`/Market/GetSMLotsForWeb/${team.id}`),
        axios.get(`/Market/GetSeasonLotsWeb/${team.id}`),
        axios.get(`/Market/GetPartyLotsForWeb/${team.id}`),
        axios.get(`/Market/GetPartyBoostLotsForWeb/${team.id}`),
        axios.get(`/Market/GetAvailibleCityLotsForWeb/${team.id}`),
        axios.get(`/Market/GetFussLotsForWeb/${team.id}`),
        axios.get(`/Market/GetKitsLotsForWeb/${team.id}`),
        axios.get(`/Market/GetBigQuestLotsForWeb/${team.id}`),
        axios.get(`/Market/GetRegularkaLotsForWeb/${team.id}`)
      ])

      //setPromocode(response[0].data)
      //setPromoProducts(response[1].data)

      setProducts(response[2].data.marketLots)

      setDaily(response[3].data)
      setWeekly(response[4].data)
      setExclusive(response[5].data)
      setRush(response[6].data)
      setSeason(response[7].data)
      setBoost(response[8].data)
      setParyt(response[9].data)
      setParytBoost(response[10].data)
      setCity(response[11].data)
      setFuss(response[12].data)
      setKits(response[13].data)
      setBigq(response[14].data)
      setReg(response[15].data)
    } catch (error) {
      sessionStorage.removeItem('team')

      setLoggedIn(false)

      console.error(error)
    }
  }

  const openModal = async (product) => {
    if(product.id === 40) {
      setSelectedProduct(product)
      setVisible(true)    
    }
    else
      openModalItself(product)
  }

  const openModalItself = async (product) =>  {
    try {
      const response = await axios.post('/Market/YookassaPurchase', {
        userId: team.id,
        marketplaceId: product.marketplaceId
      })

      window.location.href = response.data
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div>
      <div className="mt-[30px] space-y-[30px]">
      {
        isDefined(kits?.meta?.name) ?
        (
          <SpecialPromo
          data={kits.promo}
          name={kits?.meta?.name}
          heading="Акция в магазине"
          openModal={openModal}
          />
        ) : ("")
      }
      <RushCategoryNoT
          data={bigq}
          heading="Второй большой квест"
          openModal={openModal}
        />
        <RushCategory
          data={fuss}
          heading="Лихорадка"
          openModal={openModal}
        />
        <RushCategory
          data={reg}
          heading="Регулярка"
          openModal={openModal}
        />
        <Category
          data={city}
          heading="Магазин городских токенов"
          openModal={openModal}
        />
        <RushCategoryNoT
          data={season}
          heading="Покупка токенов Одиночки"
          openModal={openModal}
        />
        <RushCategory
          data={party}
          heading="Премиум награды пати"
          openModal={openModal}
        />
        <RushCategoryNoT
          data={partyBoost}
          heading="Буст пати"
          openModal={openModal}
        />
        <RushCategory
          data={rush}
          heading="Премиум награды челленджа"
          openModal={openModal}
        />
        <Category
          data={seasonBoost}
          heading="Сезон"
          openModal={openModal}
        />
        <Category
          data={exclusive}
          heading="Эксклюзивно новичку"
          openModal={openModal}
        />
        <div>
          <div className="flex flex-wrap mx-[-15px]">
            {products.map((product, index) =>
              <div
                key={index}
                className="w-full px-[15px] mt-[10px] first:mt-0 xxs:w-6/12 xxs:first-2:mt-0 sm:w-4/12 sm:first-3:mt-0"
              >
                {product.lotType && (
                  <Product
                    product={product}
                    openModal={openModal}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <Category
          data={daily}
          heading="Ежедневные товары"
          openModal={openModal}
        />
        <Category
          data={weekly}
          heading="Еженедельные товары"
          openModal={openModal}
        />
      </div>
      <div className="mt-[50px]">
        <p className="text-center font-bold text-white">Реквизиты</p>
        <div className="mt-[10px] space-y-[2px]">
          <p className="text-center text-[12px] text-white">ООО "ИКК Разработка Мобильных Игр"</p>
          <p className="text-center text-[12px] text-white">ИНН: 9731059038</p>
          <p className="text-center text-[12px] text-white">Email: <a href="mailto:ikk.games.dev@gmail.com" className="underline text-white">ikk.games.dev@gmail.com</a></p>
        </div>
      </div>
        <CSSTransition
          in={visible}
          classNames="fade"
          timeout={150}
          onExited={() => setSelectedProduct(null)}
          unmountOnExit
        >
          <Modal
            isVisible={visible}
            className="max-w-[390px]"
            closeModal={() => setVisible(false)}
          >
            <SelectRole
              product={selectedProduct}
              closeModal={() => setVisible(false)}
            />
          </Modal>
        </CSSTransition>
      </div>
  )
}

export default Shop
