import { useState, useEffect } from 'react'

import TimerDays from './TimerDays'
//import Timer from './Timer'
import Exclusive from './Exclusive'

const SpecialPromo = ({ data, name, heading, openModal }) => {
  const [products, setProducts] = useState([])
  const [timeIsOver, setTimeIsOver] = useState(false)

  useEffect(() => {
    setProducts(data.marketLots || [])
  }, [data])

  if (products.length > 0 && !timeIsOver) {
    return (
      <div>
        <div className="flex flex-col py-[18px] px-[15px] rounded-t-[8px] justify-between bg-[#293036] xxs:flex-row xxs:items-center">
          <p className="leading-[24px] text-[17px] font-bold text-[#9c55b5]">{heading}</p>
          <TimerDays
            date={new Date(data.promotionTimeEnd)}
            className="mt-[10px] xxs:mt-0"
            timeIsOver={() => setTimeIsOver(true)}
          />
        </div>
        <div className="space-y-[10px]">
          {products.map((product, index) =>
            {
              product.name = name
              return product.lotType &&
              <Exclusive
                key={index}
                product={product}
                openModal={openModal}
              />}
          )}
        </div>
      </div>
    )
  }
}

export default SpecialPromo
